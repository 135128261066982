import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

function hideMenu() {
  document.body.classList.remove('burgertime');
}

const menuItem = (props) => (
  <li
    className={props.sub ? "menu__item menu__item--sub" : "menu__item"}
    onClick={hideMenu}>
      <AniLink paintDrip hex="#6F5C61" duration={0.5} to={props.to} className={props.header ? "header" : null} activeClassName="active">{props.label}</AniLink>
  </li>
)

export default menuItem
