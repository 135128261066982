//import { Link } from "gatsby"
import React from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link";
import Toolbar from "../components/toolbar"
import Menu from "../components/menu"

const Nav = (props) => (
  <TransitionPortal>
    <footer className="navigation">
      <Toolbar prevLink={props.prevLink} nextLink={props.nextLink}></Toolbar>
      <Menu></Menu>
    </footer>
  </TransitionPortal>
)

export default Nav
