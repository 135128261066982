import AniLink from "gatsby-plugin-transition-link/AniLink"
import React from "react"

function burgerTime(event) {
  event.preventDefault();

  if(document.body.classList.contains('burgertime')) {
    document.body.classList.remove('burgertime');
  }
  else {
    document.body.classList.add('burgertime');
  }
}

const Nav = (props) => (
  <div className="toolbar">
    { props.prevLink && 
      <AniLink cover bg="#6F5C61" direction="right" duration={0.75} to={props.prevLink} className="toolbar__prev"></AniLink>
    }
    {
      !props.prevLink && 
      <div className="toolbar__prev disabled"></div>
    }

    <a href="#" className="toolbar__burger burger" onClick={burgerTime} title="Show menu">
      <span className="burger__burger"></span>
      <span className="burger__close"></span>
    </a>

    { props.nextLink && 
      <AniLink cover bg="#6F5C61" duration={0.75} to={props.nextLink} className="toolbar__next"></AniLink>
    }
    {
      !props.nextLink && 
      <div className="toolbar__next disabled"></div>
    }
  </div>
)

export default Nav
