import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LazyVideo from "../components/videoLazy"
import Nav from "../components/nav"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html, frontmatter } = markdownRemark
  const seoTitle = frontmatter.chapter + "." + frontmatter.subchapter + " " + frontmatter.title
  let tip = ""
  if(frontmatter.tip) {
    tip = <p className="tip">{frontmatter.tip}</p>;
  }

  return (
    <Layout>
      <SEO title={seoTitle} />

      <main className="chapter split">
        <LazyVideo src={frontmatter.video}></LazyVideo>

        <section className="chapter__content">
          <h1>
            <span>{frontmatter.chapter}.{frontmatter.subchapter}</span> {frontmatter.title}
          </h1>
          <article
            className="chapter__body"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {tip}
          
          <AniLink cover bg="#6F5C61" duration={0.75} to={frontmatter.next} className="next-chapter">Next chapter</AniLink>
        </section>
      </main>

      <Nav prevLink={frontmatter.prev} nextLink={frontmatter.next} />
    </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        order
        chapter
        subchapter
        video
        title
        tip
        prev
        next
      }
    }
  }
`