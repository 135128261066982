import React from "react"
import MenuItem from "../components/menuItem"

export default () => (
  <div className="menu">
    <ul>
      <li className="menu__title">
        <h2>Chapters</h2>
      </li>

      <MenuItem label="1. Adjust your chair" to="/chapters/chapter_1" />

      <MenuItem label="2. Starting position" to="/chapters/chapter_2-1" header="true" />
      <MenuItem label="Tilt Mechanism" to="/chapters/chapter_2-1" sub="true" />
      <MenuItem label="Position" to="/chapters/chapter_2-2" sub="true" />

      <MenuItem label="3. Seat Height" to="/chapters/chapter_3" />
      
      <MenuItem label="4. Backrest" to="/chapters/chapter_4-1" header="true" />
      <MenuItem label="Back Angle" to="/chapters/chapter_4-1" sub="true" />
      <MenuItem label="Backrest Height" to="/chapters/chapter_4-2" sub="true" />
      <MenuItem label="Lumbar Pump" to="/chapters/chapter_4-3" sub="true" />

      <MenuItem label="5. Seat Slide" to="/chapters/chapter_5-1" header="true" />
      <MenuItem label="Adjust the seat depth" to="/chapters/chapter_5-1" sub="true" />
      <MenuItem label="Find the right depthn" to="/chapters/chapter_5-2" sub="true" />

      <MenuItem label="6. Tension control" to="/chapters/chapter_6-1" header="true" />
      <MenuItem label="Activate tilt mechanism" to="/chapters/chapter_6-1" sub="true" />
      <MenuItem label="Tension control" to="/chapters/chapter_6-2" sub="true" />
      <MenuItem label="Lock tilt mechanism" to="/chapters/chapter_6-3" sub="true" />

      <MenuItem label="7. Armrests" to="/chapters/chapter_7-1" header="true" />
      <MenuItem label="Armrest width" to="/chapters/chapter_7-1" sub="true" />
      <MenuItem label="Armrest height" to="/chapters/chapter_7-2" sub="true" />
      <MenuItem label="Armrest position and angle" to="/chapters/chapter_7-3" sub="true" />

      <MenuItem label="8. Neckrest" to="/chapters/chapter_8" />

      <MenuItem label="9. Final adjustments" to="/chapters/chapter_9-1" header="true" />
      <MenuItem label="Level with the desk" to="/chapters/chapter_9-1" sub="true" />
      <MenuItem label="Leave the tilt mechanism unlocked" to="/chapters/chapter_9-2" sub="true" />
    </ul>
  </div>
)